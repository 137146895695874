import '@/sass/styles.scss';
import 'alpinejs';

import { VueRelay } from '@/scripts/VueRelay';

const vueComponents = [
  ['example-component', () => import('@/vue/ExampleComponent')],
];

const customComponents = [
  ['body', () => import(/* webpackChunkName: "inviewport" */ '@/scripts/InViewport')],
];

window.App = new (class App {
  constructor() {
    window.addEventListener('DOMContentLoaded', () => this.onReady());
  }

  onReady() {
    // Initialize all custom components…
    customComponents.forEach(([tag, fn]) => {
      const $els = [...document.querySelectorAll(tag)];

      if ($els.length < 1) {
        return;
      }

      (async () => {
        const Constructor = (await fn()).default;

        $els.map(($el) => (new Constructor($el)).init());
      })();
    });

    // Initialize all single Vue components…
    vueComponents.forEach(([tag, fn]) => VueRelay.parse(tag, fn));

    return this;
  }
})();
